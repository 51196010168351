.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: initial;
  background: #f3f0f0;
  padding-top: 100px;
  padding-bottom: 100px;
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
  @keyframes formIn {
    0% {
      opacity: 0;
      margin-top: 15px;
    }
    100% {
      opacity: 1;
      margin-top: 0;
    }
  }
  p {
    &.show,
    &.hide {
      button {
        background: none;
        border: none;
        padding: 0;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
        transition: 0.4s;
        &:hover {
          opacity: 0.8;
          transition: 0.4s;
        }
      }
    }
  }
  form {
    &.register-form {
      display: none;
      &.show {
        display: block;
      }
    }
    &.login-form {
      display: block;
      &.hide {
        display: none;
      }
    }
    label {
      display: block;
      font-size: 14px;
      text-align: left;
      font-weight: 700;
      color: #555;
      padding: 8px 5px;
    }
    input[type='text'],
    input[type='password'],
    input[type='email'] {
      height: 45px;
      border-radius: 5px;
      border: none;
      background: #fff;
      box-sizing: border-box;
      padding: 0 0 0 5px;
      width: 100%;
    }
  }
}
