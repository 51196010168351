$font: 'Quicksand', sans-serif;
$black: #333;
$defaultfont: 'Roboto', sans-serif;
$headingfont: 'Montserrat', sans-serif;
html {
  background: #f2f0f0;
  label {
    font-size: 16px;
    font-family: $headingfont;
  }
}
.loader-wrapper {
    position: fixed;
    width: 100%;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    z-index: 99999999;
    background: #fff;
}
.loader-wrapper.loader-is-active {
  display:none;
}
.loader {
  width: 20px;
  height: 12px;
  display: block;
  margin: auto;
  position: relative;
  border-radius: 4px;
  color: #f2dada;
  background: currentColor;
  box-sizing: border-box;
  animation: animloader 0.6s 0.3s ease infinite alternate;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  width: 20px;
  height: 12px;
  background: currentColor;
  position: absolute;
  border-radius: 4px;
  top: 0;
  right: 110%;
  animation: animloader  0.6s ease infinite alternate;
  background:#333;
}
.loader::after {
  left: 110%;
  right: auto;
  animation-delay: 0.6s;
}

@keyframes animloader {
  0% {
    width: 20px;
  }
  100% {
    width: 48px;
  }
}
.search-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
  width: 100%;

  input {
    width: 100%;
    height: 45px;
    border: 2px solid #eee;
    text-indent: 5px;
  }
  img {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 10px;
  }
}
.flex {
  display: flex;

  label {
    width: 100%;
  }
}
.notifications-container.notify-is-right.notify-is-bottom {
  z-index: 99999999;
}
.notify.notify--type-1.notify--success.notification-sig.notify--slide.notify--slideIn,
.notify.notify--type-1.notify--error.notification-sig.notify--slide.notify--slideIn {
  border-radius: 0;
  border: none;
  box-shadow: 0px 0px 22px -12px #999;
}
.mobile-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 9999999999999;
  display: none;
  @media (max-width: 1150px) {
    display: flex;
  }
  .add-another {
    max-width: 300px;
    text-align: center;
  }
}
.saved-signature-wrapper {
  height: 250px;
  overflow: scroll;
  width: calc(100% - 25px);
  display: flex;
  position:relative;
  flex-flow: column;

}
p {
  font-size: 14px;
}
.saved-signature-button {
  max-width: 400px !important;
  span {
    width: calc(100% - 20px);
    text-align: left;
  }
}

input#name-signature {
  margin-bottom: 15px;
  margin-top: 10px;
}
input::placeholder {
  font-weight: 900;
  color: rgba(0, 0, 0, 0.3) !important;
}

.modal-wrapper {
  width: calc(100% - 20px);
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 10px;
  position: relative;
  height: 250px;
  transition: 0.5s;

  &.hide {
    height: 0;
    overflow: hidden;
    transition: 0.5s;
  }

  form {
    background: #fff;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    width: 100%;
    margin-top: 15px;
  }

  fieldset {
    button {
      display: block;
      width: 100%;
    }

    label {
      display: block;
    }
  }
}

.error {
  color: #555;
  animation: blink 0.6s 1;
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 2.5px;
}

@keyframes blink {
  0 {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.signature-wrapper {
  background: #fff;
  cursor: pointer;
  padding: 25px;
  width: 500px;
  margin-bottom: 15px;
  box-shadow: 2px 2px 236px -75px rgba(0, 0, 0, 0.75);
  position:relative;
  box-sizing:border-box;
  width: auto;
  min-width:600px;
  max-width:700px;
  margin:auto;

  @media(max-width:1200px){
    width:100%;
   
  }
}

.signature-parent-wrapper {
  margin-bottom: 25px;
  padding-top: 50px;
  padding-bottom: 100px;
  margin: auto;
  position: relative;
  animation: formIn 1s 1;
  animation-fill-mode: forwards;
  top: 2.5px;
  width: calc(100% - 600px);
  margin: auto;
  text-align: center;
  justify-content: center;
  @media(max-width:1200px){
    width:calc(100% - 50px);
  }
}

@keyframes formIn {
  0% {
    opacity: 0;
    top: 2.5px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

.download-button {
  background: #fff;
  padding: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 500px;
  box-shadow: 2px 2px 236px -75px rgba(0, 0, 0, 0.75);
  padding-bottom:6px;
  box-sizing:border-box;
  margin:auto;
  button {
    margin: 0 7.5px;
    height: 45px;
    width: calc(33.33% - 15px);
    max-width: initial;
  }
}

.byline-additional {
  text-align: left;
}

.add-another {
  width: 100%;
  width: 100%;
  min-width: 160px;
  cursor: pointer;
  background: #e72e2e;
  color: #fff;
  border: none;
  border-radius: 15px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  max-width: 150px;
  font-weight: bold;
  transition: 0.4s;
  border: 2px solid #555;
  background: #f7f0f0;
  color: #555;
  border-radius: 0;
  min-width: auto;
  padding: 15px;
  border: none;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;

  &:hover {
    transition: 0.4s;
    background: #eadede;
  }
}

form fieldset label {
  background: none !important;
  color: #555 !important;
}

input[type='file'] {
  margin-bottom: 15px;
  color: #555;
  background: none;
  margin-bottom: 0;
  position: absolute;
  height: 113px;
  opacity: 0;
  z-index: 9;
  max-width: 100%;
  width: 100%;
  cursor: pointer;
}

form {
  h4 {
    width: 100%;
    /* min-width: 160px; */
    cursor: pointer;
    background: #e72e2e;
    color: #fff;
    border: none;
    border-radius: 15px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    max-width: calc(100% - 25px);
    font-weight: bold;
    transition: 0.4s;
    border: 2px solid #555;
    background: #f7f0f0;
    color: #eadede;
    border-radius: 0;
    background: #555;
    justify-content: center;
    display: flex;
    font-family: $headingfont;
    font-weight: 600;
  }
}

fieldset.flex.logo-upload {
  position: relative;
  height: 100%;

  div {
    position: relative;
  }
}

.file-loader {
  /* position: absolute; */
  height: 100px;
  position: relative;
  top: 0;
  background: #f7f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border: 2px dashed;
  border-radius: 5px;
  color: #555;
  font-size: 14px;
  font-weight: bold;
  transition: 0.4s;

  &.active {
    background: #eadede;
    transition: 0.4s;
  }
}

fieldset {
  position: relative;

  .loader {
    position: relative;
    width: 100;
    width: 100%;
    height: 5px;
    background: none;
    margin-bottom: 5px;
    overflow: hidden;
    border-radius: 5px;
    margin-top: 5px;

    span {
      position: absolute;
      width: 0;
      top: 0;
      left: 0;

      &.active {
        background: #d2cece;
        animation: loading 3.5s infinite;
        height: 100%;
        border-radius: 15px;
        transition: 0.4s;
      }
    }

    @keyframes loading {
      0% {
        width: 0;
        left: 0;
        color: #f3b8f3;
      }

      100% {
        width: 100%;
        color: #fa20fb;
      }
    }
  }
}

fieldset.flex-color.byline-extra {
  width: 100%;
}

.instruct {
  font-size: 14px;
  color: #000;
  text-align: left;
}

.flex-color {
  display: flex;
  flex-wrap: wrap;

  &.extra {
    div.field-50 {
      width: 50%;

      div.field {
        width: calc(100% - 50px);
        justify-content: initial;
      }
    }

    &.sub-extra {
      width: 100%;
    }
  }

  div {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .byline-additional {
    width: auto;
  }

  label {
    width: 100%;
  }

  input[type='text'] {
    width: calc(100% - 50px);
  }

  input[type='color'] {
    width: 100%;
    border-radius: 100%;
    border: none;
    border-radius: 15px;
  }

  input[type='color'] {
    -webkit-appearance: none;
    padding: 0;
    border: none;
    border-radius: 25px;
    width: 25px;
    height: 25px;
  }

  input[type='color']::-webkit-color-swatch {
    border: none;
    border-radius: 25px;
    padding: 0;
  }

  input[type='color']::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 25px;
    padding: 0;
  }
}

.instructions {
  position: absolute;
  left: 376px;
  background: #444;
  width: 300px;
  font-size: 15px;
  text-align: left;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 13px;
  -webkit-box-shadow: 0px 3px 63px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 63px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 63px -8px rgba(0, 0, 0, 0.75);
  animation: instructions 0.6s 1;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
  opacity: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 64px;
    background: #444;
    bottom: -3px;
    height: 20px;
    left: 14px;
    transform: rotate(-8deg);
    z-index: -1;
  }

  h5 {
    font-size: 18px;
    margin: 0;
    padding: 0 9px;
    color: #666;
  }

  ul {
    padding-left: 0;
    margin-left: 26px;
    font-size: 12px;
  }
}

@keyframes instructions {
  0% {
    opacity: 0;
    left: 490px;
  }

  100% {
    opacity: 1;
    left: 460px;
  }
}

.tooltip {
  position: absolute;
  left: -208px;
  font-size: 10px;
  background: #b05372;
  padding: 5px 15px;
  font-weight: bold;
  display: none;
  animation: blink 1.2s 1;
  text-transform: uppercase;
  width: 166px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 97px 5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 97px 5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 97px 5px rgba(0, 0, 0, 0.75);
  z-index: 2;
  text-align: left;

  &::after {
    content: '';
    display: block;
    width: 42px;
    height: 10px;
    position: absolute;
    right: 13px;
    background: #b05372;
    transform: rotate(7deg);
    bottom: -3px;
    z-index: -1;
  }
}

.wrapper {
  background: #fff;
  -webkit-box-shadow: 0px 0px 104px -49px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 104px -49px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 104px -49px rgba(0, 0, 0, 0.75);
  padding: 25px;
  border-radius: 5px;
  animation: formIn 0.7s 1;
  animation-fill-mode: forwards;
  opacity: 0;
  position: relative;
  top: 25px;
}

.green.wrapper {
  .instructions {
    background: #369934;

    &::before {
      background: #369934;
    }
  }
}

.yellow.wrapper {
  .instructions {
    background: #f9cc34;
    color: #444;

    &::before {
      background: #f9cc34;
    }
  }
}

.red.wrapper {
  .instructions {
    background: #f23435;

    &::before {
      background: #f23435;
    }
  }
}

.purple.wrapper {
  .instructions {
    background: #673499;

    &::before {
      background: #673499;
    }
  }
}

.black.wrapper {
  .instructions {
    background: #555;

    &::before {
      background: #555;
    }
  }
}

@keyframes formIn {
  0% {
    opacity: 0;
    top: 25px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

h1 {
  color: #555;
  font-size: 14px;
  text-align: left;
  font-weight: normal;

  span {
    font-weight: bold;
  }
}

form {
  margin-bottom: 5px;
  padding-bottom: 25px;

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
    background: none;

    label {
      display: block;
      font-size: 14px;
      text-align: left;
      color: #555;
      font-weight: bold;
      padding: 8px 5px;
      background: none;
    }

    input,
    select {
      width: 100%;
      left: 0;
      padding: 0;
      height: 45px;
      border-radius: 5px;
      border: none;
      background: #fff;
      box-sizing: border-box;
      padding-left: 5px;

      &::placeholder {
        color: #999;
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.active.tooltip {
  display: block;
  text-align: center;
  transition: 0.6s;
}

.copied.tooltip {
  background: #c0cb50;
  display: block;
  color: #222;
  padding-left: 75px;
  box-sizing: border-box;
  width: 190px;
  transition: 0.6s padding;

  &::after {
    content: '';
    display: block;
    width: 76px;
    height: 10px;
    position: absolute;
    right: 7px;
    background: #c0cb50;
    transform: rotate(5deg);
    bottom: -4px;
  }
}

.link-wrapper.navigation {
  right: 0;
  box-shadow: 2px 2px 236px -75px rgba(0, 0, 0, 0.75);

  .links {
    margin-top: 15px;
  }

  h2 {
    width: 100%;
    display: block;
    margin-bottom: 0;
    margin-top: 0;
    color: #555;
    font-family: $headingfont;
    font-weight: 900;
  }

  h5 {
    margin: 15px 0;
    color: #666;
  }
}

.form-login-heading {
  font-family: $headingfont;
  text-transform: uppercase;
  font-size: 11px;
  opacity: 0.8;
  color: #555;
  margin-bottom: 15px;
}
.navigation {
  width: 300px;
  height: 100%;
  position: fixed;
  z-index: 999999;
  min-height: 100vh;
  padding: 61px 25px 50px 25px;
  text-align: center;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 2px 2px 236px -75px rgba(0, 0, 0, 0.75);
  @media(max-width:1200px){
    position:relative;
    width:100%;
    height:auto;
    min-height:0;
  }
  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
    color: #555;
    font-family: $headingfont;
  }

  ul {
    li {
      font-family: $defaultfont;
    }
  }

  h5 {
    margin: 15px 0;
    font-size: 14px;
    color: #555;
  }
  h5.mini-heading {
    font-family: $headingfont;
    text-transform: uppercase;
    font-size: 11px;
    opacity: 0.8;
  }

  ul {
    padding-left: 0;

    li {
      margin-bottom: 15px;
      font-size: 14px;
      list-style: none;
      line-height: 140%;
    }
  }
}

.app-wrap {
  display: flex;
  background: #fff;
  @media(max-width:1200px){
    flex-wrap:wrap;
  }
  section.links {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .add-another {
      margin-top: 0;
      text-decoration: none;

      &.active {
        background: #555;
        color: #eadede;
      }
    }
  }
}
.saved-signatures-wrapper {
  width: 100%;
  height: 258px;
  overflow: scroll;
}
.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff !important;

  @keyframes formInInt {
    0% {
      opacity: 0;
      top: 25px;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }

  form {
    padding: 25px;
    width: 100%;
    max-width: 500px;
    background: #fff;
    box-shadow: 2px 2px 236px -75px rgba(0, 0, 0, 0.75);
    animation: formInInt 0.6s 1;
    animation-fill-mode: forwards;
    opacity: 0;
    position: relative;
    top: 25px;
    @media(max-width:600px){
      max-width:calc(100% - 100px);
    }
    label {
      display: block;
      font-size: 14px;
      text-align: left;
      font-weight: 700;
      color: #555;
      padding: 8px 5px;
    }

    input[type='text'],
    input[type='password'] {
      height: 45px;
      border-radius: 5px;
      border: none;
      background: #fff;
      box-sizing: border-box;
      padding: 0 0 0 5px;
      width: 100%;
    }
  }
}
